/* margin classes */

.mb-2 {
	margin-bottom: 2rem !important;
}

.mb-3 {
	margin-bottom: 6rem !important;
}

.mb-4 {
	margin-bottom: 8rem !important;
}

.mb-6 {
	margin-bottom: 12rem !important;
}

.container {
	--max-size: 1300px;
	width: min(var(--max-size), 95%);
	padding: 0 1rem;
	margin-inline: auto;
}

.container--narrow {
	--max-size: 1140px;
}

.btn {
	display: inline-block;
	padding: 0.75em 1.5em;
	background-color: var(--btn-bg);
	color: #fff;
	text-decoration: none;
	outline: none;
	cursor: pointer;
	border-radius: 0.25rem;
	transition: outline-offset 100ms ease-in-out;
}

.btn:hover,
.btn:focus-visible {
	outline: 2px solid var(--btn-bg);
	outline-offset: 0.25rem;
}

.btn--primary {
	--btn-bg: #2c80fe;
}

.btn--secondary {
	--btn-bg: #000;
}

.flow > *:not(:last-child) {
	margin-bottom: var(--spacer, 1rem);
}
