:root {
	--font-libre: 'Libre Franklin', sans-serif;
	--font-sans-serif: sans-serif;
	--font-arial: Arial, sans-serif;
	--font-verdana: Verdana, sans-serif;
	--font-lato: 'Lato', sans-serif;

	--clr-neutral-700: rgb(39, 39, 37);
}

body {
	font-family: var(--font-libre);
}

/* hero */

.home .hero {
	height: 70vh;
}

.home .hero img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* hero content */

.hero-content .heading {
	font-size: clamp(2rem, 1.5rem + 3vw, 3.5rem);
	max-width: 25ch;
	line-height: 1;
	text-transform: uppercase;
	margin-bottom: 1.5rem;
}

.hero-content .heading span {
	color: rgb(255, 205, 0);
}

.hero-content .split {
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 1rem;
}

.hero-content .left h2 {
	font-size: 3rem;
	font-weight: 900;
	text-transform: uppercase;
	width: min-content;
	margin-right: auto;
	color: var(--clr-neutral-700);
}

.hero-content .card,
.achievements-section .card {
	padding: 5rem;
	background-color: hsl(46, 100%, 50%);
	position: relative;
}

.hero-content .card h3 {
	font-size: 3rem;
	text-align: center;
	margin-bottom: 0.75em;
}

.hero-content .card p {
	font-family: var(--font-arial);
	line-height: 29px;
	font-size: 1.125rem;
	font-weight: 400;
}

.hero-content .card .btn {
	position: absolute;
	padding: 0.75rem 2rem;
	right: 0;
	bottom: -1.5rem;
}

/* highlights */

.highlights {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
	gap: 1.5rem;
}

.highlight {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1rem;
	border-radius: 0.25rem;
}

.highlight .h3 {
	font-weight: 400;
	font-size: 4rem;
	font-family: var(--font-verdana);
}

.highlight p {
	font-weight: 300;
	font-size: 1.5rem;
	line-height: 1.2;
}

/* Expertise */

.expertise-section header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.expertise-section header h2,
.achievements-section header p {
	width: 100%;
	font-size: 1.625rem;
	font-weight: 700;
	color: #c1c1c1;
}

.expertise-section header h2 {
	text-transform: uppercase;
}

.expertise-section header p,
.achievements-section header h2 {
	font-size: clamp(2.825rem, 2rem + 2vw, 4rem);
	text-transform: uppercase;
	line-height: 1;
	font-weight: 900;
	max-width: 15ch;
	color: var(--clr-neutral-700);
}

.expertises {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
	gap: 2rem;
}

.expertise h3 {
	text-transform: uppercase;
	font-size: 2.125rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid var(--border-clr, #2c80fe);
}

.expertise h3[data-title='Code'] {
	--border-clr: #1c55f2;
}

.expertise h3[data-title='Design'] {
	--border-clr: #ffcd00;
}

.expertise h3[data-title='Strategy'] {
	--border-clr: #ee1b3c;
}

.expertise ul {
	list-style: none;
	font-family: var(--font-lato);
}

/* Achievements */

.achievements-section .container {
	--spacer: 3rem;
}

.achievements-video {
	overflow: hidden;
}

.achievements-video video {
	height: 100%;
	width: 100%;
	object-fit: fill;
}

.achievements-section header h2 {
	width: 100%;
	max-width: 100%;
	word-break: break-word;
	font-size: clamp(2.825rem, 2rem + 2vw, 4rem);
}

.achievements-section .recommendation {
	--spacer: 1rem;
	margin-bottom: 1rem;
}

.achievements-section .recommendation h3 {
	font-family: var(--font-sans-serif);
	font-size: clamp(2.5rem, 2rem + 2vw, 4.5rem);
	text-transform: uppercase;
	font-weight: 900;
	/* text-align: center; */
	word-break: break-word;
	line-height: 1;
}

.achievements-section .recommendation span {
	color: #ee1b3c;
}

.achievements-section .recommendation p {
	color: #929292;
	font-style: italic;
}

.achievements-section .recommendation img {
	width: 100%;
	object-fit: cover;
}

.achievements-section .card {
	padding: 8rem 3rem 4rem;
}

.achievements-section .card h3 {
	font-size: clamp(2rem, 1.75rem + 2vw, 4rem);
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 0.75rem;
}

.achievements-section .card p {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 2.5rem;
}

.achievements-section .card__images {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
	gap: 0.5rem;
	grid-auto-rows: 1fr;
}

.achievements-section .card__image {
	width: 100%;
	height: 100%;
}

/* Footer */

.footer {
	background-color: #272725;
	color: #fff;
	padding-top: 2rem;
}

.footer .grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
	gap: 1.5rem;
}

.footer .grid-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.footer .grid-item:first-child p {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 2rem;
}

.footer .grid-item h3 {
	font-size: 1.5rem;
	margin-bottom: 1em;
}

.footer .grid-item ul {
	list-style: none;
}

.footer .grid-item p {
	margin-bottom: 1rem;
}

.footer .grid-item a {
	color: inherit;
	text-decoration: none;
}

.footer .hover-underline:hover,
.footer .hover-underline:focus-visible {
	border-bottom: 1px solid #fff;
	outline: none;
}

.footer .grid-item:last-child {
	justify-content: flex-start;
}

.footer .social__items {
	display: flex;
	gap: 1.5rem;
}

.footer .social__item a {
	display: inline-flex;
	padding: 0.5rem;
	background-color: #fff;
	color: #000;
	border-radius: 50%;
	transition: outline-offset 100ms ease;
}

.footer .social__item a:hover {
	outline: 1px solid #fff;
	outline-offset: 0.2rem;
	border: 0;
}

.footer .copyright {
	background-color: #222;
	padding: 0.5rem 1.5rem;
	text-align: center;
	font-size: 0.875rem;
}

@media (max-width: 60em) {
	.hero-content .heading {
		max-width: 20ch;
	}

	.hero-content .split {
		grid-template-columns: 1fr;
	}

	.hero-content .left h2 {
		width: 100%;
		text-align: center;
	}

	.hero-content .card .btn {
		position: static;
	}
	.achievements-section .card__image {
		width: 100%;
	}
}

@media (max-width: 40em) {
	.home .hero {
		height: 60vh;
	}

	.hero-content .card {
		padding: 2rem 2.5rem;
	}
}
