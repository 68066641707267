/* hamburger */

.hamburger {
	display: none;
	background-color: transparent;
	width: 3rem;
	height: 3.5rem;
	border: none;
	padding: 0;
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	z-index: 101;
	-webkit-border-radius: 0.5rem;
	-moz-border-radius: 0.5rem;
	-ms-border-radius: 0.5rem;
	-o-border-radius: 0.5rem;
}

.hamburger:focus {
	outline: none;
}

.hamburger .inner,
.hamburger .inner::before,
.hamburger .inner::after {
	position: absolute;
	height: 0.22rem;
	background: #333;
	border-radius: 0.25rem;
	display: block;
	transition: top 0.2s 0.2s, transform 0.2s, background-color 0.4s 0.2s;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	-ms-border-radius: 0.25rem;
	-o-border-radius: 0.25rem;
	-webkit-transition: top 0.2s 0.2s, transform 0.2s, background-color 0.4s 0.2s;
	-moz-transition: top 0.2s 0.2s, transform 0.2s, background-color 0.4s 0.2s;
	-ms-transition: top 0.2s 0.2s, transform 0.2s, background-color 0.4s 0.2s;
	-o-transition: top 0.2s 0.2s, transform 0.2s, background-color 0.4s 0.2s;
}

.hamburger .inner {
	width: 78%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.hamburger .inner::before,
.hamburger .inner::after {
	content: '';
	left: 0;
	width: 100%;
}

.hamburger .inner::before {
	top: -0.75rem;
}

.hamburger .inner::after {
	top: 0.75rem;
}

/* animation */
.hamburger.menu-active .inner {
	background-color: transparent;
	transform: backgorund-color 0.2s;
	-webkit-transform: backgorund-color 0.2s;
	-moz-transform: backgorund-color 0.2s;
	-ms-transform: backgorund-color 0.2s;
	-o-transform: backgorund-color 0.2s;
}

.hamburger.hamburger.menu-active .inner::before {
	transform: rotate(-45deg);
	top: 0;
	transition: top 0.2s, transform 0.2s 0.14s;
	-webkit-transition: top 0.2s, transform 0.2s 0.14s;
	-moz-transition: top 0.2s, transform 0.2s 0.14s;
	-ms-transition: top 0.2s, transform 0.2s 0.14s;
	-o-transition: top 0.2s, transform 0.2s 0.14s;
}

.hamburger.hamburger.menu-active .inner::after {
	transform: rotate(45deg);
	top: 0;
	transition: top 0.2s, transform 0.2s 0.14s;
	-webkit-transition: top 0.2s, transform 0.2s 0.14s;
	-moz-transition: top 0.2s, transform 0.2s 0.14s;
	-ms-transition: top 0.2s, transform 0.2s 0.14s;
	-o-transition: top 0.2s, transform 0.2s 0.14s;
}

/* navbar */

.header {
	position: relative;
	background-color: #fff;
	box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
	padding: 0.5rem 0;
}

.header .container {
	overflow: visible;
}

.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
}

.logo {
	width: 7rem;
	display: flex;
	align-items: center;
}

.nav__items {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1.75rem;
	list-style: none;
	font-size: 1rem;
	background-color: #fff;
}

.nav__items a {
	color: inherit;
	text-decoration: none;
}

.nav__item {
	cursor: pointer;
	text-transform: uppercase;
	font-weight: bold;
	position: relative;
}

.nav__item span {
	display: flex;
	gap: 1rem;
	justify-content: space-between;
	align-items: center;
}

.inner__nav__items {
	list-style: none;
	position: absolute;
	width: max-content;
	background-color: #fff;
	box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
	display: none;
	z-index: 1;
}

.inner__nav__item a {
	display: block;
	padding: 0.75rem 1.25rem;
	border-bottom: 1px solid #999;
}

:is(.nav__item:hover, .nav__item:focus-visible) .inner__nav__items {
	display: block;
}

.inner__nav__item:last-child a {
	border: 0;
}

@media (max-width: 60em) {
	.hamburger {
		display: block;
	}

	.nav__items {
		display: none;
		position: absolute;
		top: calc(2.8125rem + 1rem);
		left: 0;
		padding: 0 1rem;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		gap: 0;
	}

	.nav__item {
		width: 100%;
		border-bottom: 1px solid #999;
	}

	.nav__item span {
		padding: 0.75rem 0;
	}

	.inner__nav__items {
		position: static;
		width: 100%;
		box-shadow: none;
		display: none;
		z-index: 1;
	}

	.hamburger.menu-active + .nav__items {
		display: block;
	}
}
